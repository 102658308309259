.header {
  display: flex;
  align-items: center;
  height: 100px;
}

.content {
  width: 1000px;
  margin: 0 auto;
  padding: 20px 0
}

.white-bg {
  background-color: #fff;
}

.website-title {
  width: auto;
  padding: 0;
  color: #fff;
  font-size: 18pt;
  border-radius: 6px;
  margin: 0 12px;
}